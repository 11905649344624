@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Courier+Prime:ital,wght@0,400;0,700;1,400;1,700&display=swap");

@font-face {
  font-family: "Bebas regular";
  src: url("./themes/fonts/BebasNeueRegular.otf") format("opentype");
  font-style: normal;
}

@font-face {
  font-family: "Bebas bold";
  src: url("./themes/fonts/BebasNeueBold.otf") format("opentype");
  font-style: normal;
}

@font-face {
  font-family: "Hurme geometric light";
  src: url("./themes/fonts/HurmeGeometricLight.otf") format("opentype");
  font-style: normal;
}

@font-face {
  font-family: "Hurme geometric regular";
  src: url("./themes/fonts/HurmeGeometricSansRegular.otf") format("opentype");
  font-style: normal;
}

@font-face {
  font-family: "Hurme geometric semibold";
  src: url("./themes/fonts/HurmeGeometricSansSemiBold.otf") format("opentype");
  font-style: normal;
}

@font-face {
  font-family: "Hurme geometric bold";
  src: url("./themes/fonts/HurmeGeometricSansBold.otf") format("opentype");
  font-style: normal;
}

html {
  scroll-behavior: smooth;
  background-image: url("/src/themes/img/bg.jpg");
  background-position: center;
  background-size: contain;
}
